import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { verifyAuth } from "../api";
import { useLocation } from "react-router-dom";
import { useGlobalContext } from "../Context/GlobalContext";

export default function usePermission() {
  const { permissionState } = useGlobalContext();
  const [permissions, setPermissions] = permissionState;

  useEffect(() => {
    getCameraState();
    getLocationState();
    getMicrophoneState();
  }, []);

  const getLocationState = () => {
    if (navigator?.geolocation) {
      if (!navigator?.permissions?.query) {
        setPermissions((st) => ({ ...st, location: "NOTAVAIL" }));
        return;
      }

      navigator?.permissions
        ?.query({ name: "geolocation" })
        .then(async function (result) {
          if (result.state === "granted") {
            setPermissions((st) => ({ ...st, location: "GRANTED" }));
          } else if (result.state === "prompt") {
            setPermissions((st) => ({ ...st, location: "PROMPT" }));
          } else if (result.state === "denied") {
            setPermissions((st) => ({ ...st, location: "DENIED" }));
          }
          result.onchange = function () {
            getLocationState();
          };
        });
    } else {
      setPermissions((st) => ({ ...st, location: "NOTAVAIL" }));
    }
  };

  const getCameraState = () => {
    if (navigator?.mediaDevices?.getUserMedia) {
      if (!navigator.permissions?.query) {
        setPermissions((st) => ({ ...st, camera: "NOTAVAIL" }));
        return;
      }

      navigator.permissions
        .query({ name: "camera" })
        .then(async function (result) {
          if (result.state === "granted") {
            setPermissions((st) => ({ ...st, camera: "GRANTED" }));
          } else if (result.state === "prompt") {
            setPermissions((st) => ({ ...st, camera: "PROMPT" }));
          } else if (result.state === "denied") {
            setPermissions((st) => ({ ...st, camera: "DENIED" }));
          }
          result.onchange = function () {
            getCameraState();
          };
        });
    } else {
      setPermissions((st) => ({ ...st, camera: "NOTAVAIL" }));
    }
  };

  const getMicrophoneState = () => {
    if (navigator?.mediaDevices?.getUserMedia) {
      if (!navigator.permissions?.query) {
        setPermissions((st) => ({ ...st, microphone: "NOTAVAIL" }));
        return;
      }

      navigator.permissions
        .query({ name: "microphone" })
        .then(async function (result) {
          if (result.state === "granted") {
            setPermissions((st) => ({ ...st, microphone: "GRANTED" }));
          } else if (result.state === "prompt") {
            setPermissions((st) => ({ ...st, microphone: "PROMPT" }));
          } else if (result.state === "denied") {
            setPermissions((st) => ({ ...st, microphone: "DENIED" }));
          }
          result.onchange = function () {
            getMicrophoneState();
          };
        });
    } else {
      setPermissions((st) => ({ ...st, microphone: "NOTAVAIL" }));
    }
  };

  return {
    permissions,
  };
}
