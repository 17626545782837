import React, { createContext, useContext, useState } from "react";
import { useEffect } from "react";

const KYCContext = createContext();
export const KYCContextProvider = ({ children }) => {
  const [questionsArray, setQuestionsArray] = useState([]);
  const [videoArray, setVideoArray] = useState(["way","outside","gate","inside","meter"]);
  const [kycDetails, setKycDetails] = useState({
    loaded: false,
  });

  return (
    <KYCContext.Provider
      value={{
        kycDetailsState: [kycDetails, setKycDetails],
        kycDetails,
        questionsArray,
        setQuestionsArray,
        videoArray,
        setVideoArray
      }}
    >
      {children}
    </KYCContext.Provider>
  );
};

export const useKYCContext = () => {
  return useContext(KYCContext);
};
