import React, { createContext, useContext, useRef, useState } from "react";
import { useEffect } from "react";

const GlobalContext = createContext();
export const GlobalProvider = ({ children }) => {
  const [interacted, setInteracted] = useState(false);
  const [permissions, setPermissions] = useState({
    camera: "PROMPT",
    location: "PROMPT",
    microphone: "PROMPT",
  });

  
  const audioRef = useRef(null);

  function clickEventListener() {
    setInteracted(true);
    document.body.removeEventListener("click", clickEventListener, true);
  }

  useEffect(() => {
    console.log(permissions);
  }, [permissions]);

  return (
    <GlobalContext.Provider
      value={{
        interacted,
        setInteracted,
        clickEventListener,
        permissionState: [permissions, setPermissions],
        audioRef
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(GlobalContext);
};
