import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import App from "./App";
import { GlobalProvider } from "./Context/GlobalContext";
import { KYCContextProvider } from "./Context/KYCContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GlobalProvider>
    <KYCContextProvider>
      <App />
    </KYCContextProvider>
  </GlobalProvider>
);
