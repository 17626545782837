import { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useGlobalContext } from "./Context/GlobalContext";
import {
  addClickListener,
  removeClickListener,
} from "./utility/interationListnerUtil";
import usePermission from "./hooks/usePermissions";
import "./App.css";
import "./styles/kyc.css";
import { CircularProgress } from "@mui/material";

const BasicDetails = lazy(() => import("./Pages/BasicDetails"));
const AudioRecorder = lazy(() => import("./components/Recorder"));
const TestReco = lazy(() => import("./Pages/TestRecoPage"));
const AssemplyTest = lazy(() => import("./Pages/AssemplyTest"));
const KYCRoutes = lazy(() => import("./Routes/KYCRoutes"));
const AccessPage = lazy(() => import("./Pages/KYC/AccessPage"));
const PermissionScreen = lazy(() => import("./Pages/KYC/PermissionScreen"));
const VideoCompressor = lazy(() => import("./Pages/TestCamera"));
const PermissionsModal = lazy(() => import("./components/PermissionsModal"));

function App() {
  const { setInteracted, clickEventListener } = useGlobalContext();
  usePermission();

  useEffect(() => {
    addClickListener(clickEventListener);
    return () => {
      removeClickListener(clickEventListener);
    };
  }, [setInteracted]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Router>
        <Routes>
          <Route exact path="test" element={<VideoCompressor />} />
          <Route exact path="assemply" element={<AssemplyTest />} />
          <Route exact path="" element={<PermissionScreen />} />
          <Route exact path=":type" element={<PermissionScreen />} />
          <Route exact path=":type/:kycId" element={<PermissionScreen />} />
          <Route path="kyc/*" element={<KYCRoutes />} />
        </Routes>
      </Router>
      <PermissionsModal/>
    </>
  );
}

export default function AppWithSuspense() {
  return (
    <Suspense
      fallback={
        <div className="suspense-loader">
          <CircularProgress />
        </div>
      }
    >
      <App />
    </Suspense>
  );
}
