import axios from "axios";
import { URL, environment } from "../config";

const API = axios.create({
  baseURL: URL[environment],
  withCredentials: true,
});


// API.interceptors.request.use(
//   config => {
//       const nbfc = localStorage.getItem('nbfc');
//       // config.headers['nbfc-id'] = nbfc;
//       config.headers['application'] = "AGENT-ERICK-V2";
//       return config;
//   },
//   error => {
//       Promise.reject(error)
// });

const getProgress = (setState) => {
  return {
    onUploadProgress: (data) => {
      setState(Math.round((100 * data.loaded) / data.total));
    },
    timeout: 180000,
  };
};


export const fetchKYCDetails = (id) => API.get(`/api/v1/kyc/video/kyc-data/${id}`);
export const panUpload = (data) => API.post(`/api/v1/kyc/video/pan-upload`, data);
export const updateAnswer = (data, setProgress) => API.post(`/api/v1/kyc/video/add-audio-docs`, data, getProgress(setProgress));
export const videoUpload = (data, setProgress) => API.post(`/api/v1/kyc/video/add-video-docs`, data , getProgress(setProgress));
export const updateMarriageInfo = (data) => API.post(`/api/v1/kyc/video/add-info-question`, data);